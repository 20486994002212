import React from 'react';
import { useLocation } from 'react-router-dom';

export default function PrivacyPopup() {
    const location = useLocation();
    console.log(location);
    const [cookieConsent, setCookieConsent] = React.useState(
        document.cookie.includes('cookieConsent=') ? true : null
    );

    if (cookieConsent !== null || location?.pathname === '/privacy') {
        return null;
    }

    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                background: 'rgba(0,0,0,0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1000,
            }}
        >
            <div
                style={{
                    padding: '16px',
                    background: 'white',
                    borderRadius: '8px',
                    color: 'black',
                    maxWidth: '720px',
                }}
            >
                <section style={{ marginBottom: '32px' }}>
                    <h2>Cookie consent</h2>
                    <p>
                        We use cookies to provide the best experience on
                        musicbysounds.com, for analysis, and to personalize
                        content. You can read more and edit your cookie settings
                        via the link below. If you wish to update your cookie
                        settings at a later time, you can always find a link to
                        this page at the bottom of the site under “Privacy
                        Policy.” To consent to the full use of cookies, click “I
                        accept.”
                    </p>
                    <p>
                        <a
                            style={{ color: '#00aa99' }}
                            href="/privacy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Read our privacy policy.
                        </a>
                    </p>
                </section>
                <div
                    style={{
                        display: 'flex',
                        gap: '8px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <button
                        className="Button tertiary"
                        onClick={(e) => {
                            e.preventDefault();
                            document.cookie =
                                'cookieConsent=false; max-age=31536000; path=/';
                            setCookieConsent(false);
                        }}
                    >
                        I do not accept
                    </button>
                    <button
                        className="Button primary"
                        onClick={(e) => {
                            e.preventDefault();
                            document.cookie =
                                'cookieConsent=true; max-age=31536000; path=/';
                            setCookieConsent(true);
                        }}
                    >
                        I accept
                    </button>
                </div>
            </div>
        </div>
    );
}
