import Header from './components/Header/Header';
import MusicPage from './components/MusicPage/MusicPage';
import SingleMusicPage from './components/MusicPage/SingleMusicPage';
import AboutPage from './components/AboutPage/AboutPage';
import ContactPage from './components/ContactPage/ContactPage';
import HomePage from './components/HomePage/HomePage';
import Button from './components/Button/Button';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    Redirect,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import SingleMusicPageRedirect from './components/MusicPage/SingleMusicPageRedirect';
import PrivacyPage from './components/PrivacyPage/PrivacyPage';
import React from 'react';
import PrivacyPopup from './components/PrivacyPopup/PrivacyPopup';

function App() {
    const location = useLocation();

    return (
        <div className="App">
            <PrivacyPopup />
            <Header />
            <main className="site-content">
                {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
                <TransitionGroup className="site-content__wrapper">
                    <CSSTransition
                        timeout={1500}
                        classNames="fade"
                        key={location.key}
                    >
                        <Switch location={location}>
                            <Route exact path="/">
                                <HomePage />
                            </Route>
                            <Route path="/music/:slug">
                                <SingleMusicPage />
                            </Route>
                            <Route path="/music">
                                <MusicPage />
                            </Route>
                            <Route path="/about">
                                <AboutPage />
                            </Route>
                            <Route path="/contact">
                                <ContactPage />
                            </Route>
                            <Route path="/privacy">
                                <PrivacyPage />
                            </Route>
                            <Route path="/:slug">
                                <SingleMusicPageRedirect />
                            </Route>
                            <Route path="*">
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </main>
        </div>
    );
}

export default App;
