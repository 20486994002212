import './Header.scss';
import Logo from '../Logo/Logo';
import Navigation from './Navigation/Navigation';
import SomeIcons from './SomeIcons/SomeIcons';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Header() {
    const [activeMenu, setActiveMenu] = useState(false);
    const [headerCreated, setHeaderCreated] = useState(false);
    const [burgerCreated, setBurgerCreated] = useState(false);
    const burgerElMobile = useRef(null);
    const burgerElDesktop = useRef(null);
    const headerEl = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setBurgerCreated(true);
        }, 1250);

        setTimeout(() => {
            setHeaderCreated(true);
        }, 1000);
    }, []);

    return (
        <header
            ref={headerEl}
            className={`Header${activeMenu ? ' active' : ''}${
                headerCreated ? ' show' : ''
            }`}
        >
            <div className="Header__content">
                <div
                    className="Header__ticker"
                    onClick={() => setActiveMenu(!activeMenu)}
                ></div>
                <div
                    ref={burgerElDesktop}
                    className={`Header__burger ${burgerCreated ? 'show' : ''}`}
                ></div>
                <div className="Header__branding">
                    <Link to="/" onClick={() => setActiveMenu(false)}>
                        <Logo />
                    </Link>
                </div>
                <div className="Header__nav">
                    <Navigation onNavClick={() => setActiveMenu(false)} />
                </div>
                <div className="Header__footer">
                    <div
                        style={{ marginBottom: '16px', fontSize: '0.8rem' }}
                        className="Navigation__item"
                    >
                        <Link
                            to="/privacy"
                            onClick={() => setActiveMenu(false)}
                        >
                            Privacy policy
                        </Link>
                    </div>
                    <SomeIcons />
                </div>
            </div>
        </header>
    );
}

export default Header;
