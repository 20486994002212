import React from 'react';

export default function PrivacyPage() {
    return (
        <div
            style={{
                height: '100%',
                overflowY: 'auto',
            }}
        >
            <div
                style={{
                    padding: '32px 8px',
                    maxWidth: '720px',
                    margin: '0 auto',
                }}
            >
                <header>
                    <h1>Privacy Policy</h1>
                </header>

                <section>
                    <p>
                        This statement explains how and what information this
                        website collects about its visitors. Providing personal
                        data is voluntary, but visitors are still entitled to
                        information regarding this.
                    </p>
                </section>

                <section>
                    <h2>What Personal Data We Collect and Why We Collect It</h2>

                    <article>
                        <h3>Contact Forms</h3>
                        <p>
                            If you use our contact form or send us an email to
                            get in touch, the message may be stored in our
                            mailbox. Information such as your name and email
                            address is stored so that we can contact you. In the
                            text fields, we recommend that you only write
                            content relevant to your message and avoid providing
                            sensitive information about yourself or others.
                        </p>
                    </article>

                    <article>
                        <h3>Cookies</h3>

                        <h4>About Cookies</h4>
                        <p>
                            Cookies are small text files that the website stores
                            on the user’s computer. A cookie consists only of
                            text content and is not software. A common example
                            of cookies in use is during online shopping, where a
                            virtual shopping cart remembers its contents even if
                            you leave the website and return later.
                        </p>

                        <h4>Web Analytics</h4>
                        <p>
                            As part of our effort to create a user-friendly
                            website, we analyze the usage patterns of our
                            visitors. To process this data, we use the analytics
                            tool Google Analytics. Google Analytics uses cookies
                            to register users’ IP addresses, which provide
                            insights into individual browsing behaviors.
                            Examples of data collected include: how many people
                            visit specific pages, how long visits last, which
                            websites users come from, and which browsers are
                            used.
                        </p>

                        <p>
                            None of these cookies allow us to link the collected
                            data to you as an individual. Information collected
                            by Google Analytics is stored on Google’s servers in
                            the USA and is subject to Google’s privacy policies.
                            Since an IP address can be traced back to specific
                            hardware and individuals, we anonymize the IP
                            address before the data is stored and processed by
                            Google. As a result, the stored IP address cannot be
                            used to identify individual users.
                        </p>

                        <p>
                            You can read more about how Google uses data here:{' '}
                            <a
                                href="https://www.google.com/policies/privacy/partners/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.google.com/policies/privacy/partners/
                            </a>
                        </p>
                    </article>

                    <article>
                        <h3>Embedded Content from Other Websites</h3>
                        <p>
                            Articles on this site may include embedded content
                            (e.g., videos, images, articles, etc.). Embedded
                            content from other websites behaves in the same way
                            as if the visitor had visited the original site
                            hosting the embedded content.
                        </p>
                        <p>
                            These websites may collect information about you,
                            use cookies, embed third-party tracking systems, and
                            monitor your interactions with the embedded content,
                            including tracking your actions if you have an
                            account and are logged in on their site.
                        </p>
                    </article>
                </section>

                <section>
                    <h2>How Long We Retain Your Data</h2>
                    <p>
                        If you use the contact form or contact us in writing in
                        any way, the message and information may be stored
                        indefinitely. This is to allow us to follow up on
                        previous matters if necessary. Upon request, we can
                        delete the message and information.
                    </p>
                </section>

                <section>
                    <h2>Your Rights Regarding Data We Hold About You</h2>
                    <p>
                        If you have submitted a contact request via the contact
                        form, you can request an export file containing the
                        personal data we have about you. This includes all the
                        data you have provided to us. You can also request that
                        we delete all personal data we have about you. This does
                        not include data we are required to retain for
                        administrative, legal, or security purposes.
                    </p>
                </section>

                <section>
                    <h2>How to Manage Cookies</h2>
                    <p>
                        To update your cookie preferences for this site, you can
                        delete the "cookieConsent" cookie associated with this
                        site through your browser’s cookie management tools.
                    </p>
                    <p>
                        For detailed instructions on managing cookies, please
                        visit{' '}
                        <a
                            href="https://allaboutcookies.org/how-to-manage-cookies"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://allaboutcookies.org/how-to-manage-cookies
                        </a>{' '}
                        or similar resources. These pages provide guidance on
                        configuring your browser to accept or reject cookies.
                    </p>
                </section>

                <section>
                    <h2>Contact Information</h2>
                    <p>
                        If you have questions or wish to contact us regarding
                        our processing of your personal data, please reach out
                        at{' '}
                        <a href="mailto:contact@musicbysounds.com">
                            contact@musicbysounds.com
                        </a>
                        .
                    </p>
                </section>
            </div>
        </div>
    );
}
